import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueBodyClass from 'vue-body-class'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const userData = JSON.parse(localStorage.getItem('userData'))
const userRole = userData && userData.role ? userData.role : null

const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

const routes = [
  {
    path: '/',
    redirect: to => {
      if (userRole) return { name: 'dashboard' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      bodyClass: 'public',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      bodyClass: 'public',
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      bodyClass: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
    },
  },
  {
    path: '/error-405',
    name: 'error-405',
    component: () => import('@/views/Error405.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      bodyClass: 'public',
      resource: 'Public',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Index.vue'),
    meta: {
      layout: 'content',
      resource: 'Dashboard',
    },
  },
  {
    path: '/admin/list',
    name: 'admin-list',
    component: () => import('@/views/admin/admin-list/AdminList.vue'),
    meta: {
      layout: 'content',
      resource: 'Users',
    },
  },
  {
    path: '/sms/list',
    name: 'sms-list',
    component: () => import('@/views/sms/sms-list/SmsList.vue'),
    meta: {
      layout: 'content',
      resource: 'Users',
    },
  },

  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('@/views/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
      resource: 'Users',
    },
  },
  {
    path: '/user/view',
    name: 'user-view',
    component: () => import('@/views/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
      navActiveLink: 'user-list',
    },
  },
  {
    path: '/user/addresses',
    name: 'user-addresses',
    component: () => import('@/views/user/user-addresses/UserAddresses.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
      navActiveLink: 'user-list',
    },
  },
  {
    path: '/user/contracts',
    name: 'user-contracts',
    component: () => import('@/views/user/user-contracts/UserContracts.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
      navActiveLink: 'user-list',
    },
  },
  {
    path: '/order/order-easy',
    name: 'order-easy',
    component: () => import('@/views/order/order-easy/OrderEasy.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
    },
  },
  {
    path: '/order/completed',
    name: 'order-completed',
    component: () => import('@/views/order/completed/Completed.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
    },
  },
  {
    path: '/order/error',
    name: 'order-error',
    component: () => import('@/views/order/error/Error.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
    },
  },
  {
    path: '/extract',
    name: 'extract-list',
    component: () => import('@/views/extract/ExtractList.vue'),
    meta: {
      layout: 'content',
      resource: 'QuickOrder',
    },
  },

  {
    path: '/documents/:folder',
    name: 'documents-list',
    component: () => import('@/views/documents/DocumentsList.vue'),
    meta: {
      layout: 'content',
      resource: 'QuickOrder',
    },
    beforeEnter(to, _, next) {
      if (['order', 'waybill', 'bill'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/purchases',
    name: 'purchases',
    component: () => import('@/views/purchases/Purchases.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
    },
  },

  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/payment/Payment.vue'),
    meta: {
      layout: 'content',
      resource: 'PaymentResult',
    },
  },

  {
    path: '/credit-card-transactions',
    name: 'credit-card-transactions',
    component: () => import('@/views/creditCardTransactions/DataList.vue'),
    meta: {
      layout: 'content',
      resource: 'Payment',
    },
  },
  {
    path: '/pos-tanimlari',
    name: 'pos-tanimlari',
    component: () => import('@/views/pos-settings/posTanimlariList.vue'),
    meta: {
      layout: 'content',
      resource: 'Payment',
    },
  },
  {
    path: '/pos-tanimlari-ekle',
    name: 'pos-tanimlari-ekle',
    component: () => import('@/views/pos-settings/posTanimlari.vue'),
    meta: {
      layout: 'content',
      resource: 'Payment',
    },
  },
  {
    path: '/pos-tanimlari-duzenle/:id',
    name: 'pos-tanimlari-ekle',
    component: () => import('@/views/pos-settings/posTanimlari.vue'),
    meta: {
      layout: 'content',
      resource: 'Payment',
    },
  },
  {
    path: '/pos-tanimlari-duzenle/:id/:copy',
    name: 'pos-tanimlari-ekle',
    component: () => import('@/views/pos-settings/posTanimlari.vue'),
    meta: {
      layout: 'content',
      resource: 'Payment',
    },
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/logs/DataList.vue'),
    meta: {
      layout: 'content',
      resource: 'Logs',
    },
  },
  {
    path: '/aging-report',
    name: 'aging-report',
    component: () => import('@/views/agingReport/AgingReport.vue'),
    meta: {
      layout: 'content',
      resource: 'Payment',
    },
  },
  {
    path: '/product/list',
    name: 'product-list',
    component: () => import('@/views/products/product-list/ProductList.vue'),
    meta: {
      layout: 'content',
      resource: 'ProductsMan',
    },
  },
  {
    path: '/product/view',
    name: 'product-view',
    component: () => import('@/views/products/product-view/ProductView.vue'),
    meta: {
      layout: 'content',
      resource: 'ProductsMan',
      navActiveLink: 'product-list',
    },
  },
  {
    path: '/event/list',
    name: 'event-list',
    component: () => import('@/views/events/event-list/EventList.vue'),
    meta: {
      layout: 'content',
      resource: 'EventsMan',
    },
  },
  {
    path: '/event/view',
    name: 'event-view',
    component: () => import('@/views/events/event-view/EventView.vue'),
    meta: {
      layout: 'content',
      resource: 'ProductsMan',
      navActiveLink: 'event-list',
    },
  },
  {
    path: '/order-tracking',
    name: 'order-tracking',
    component: () => import('@/views/orderTracking/tracking-list/TrackingList.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
    },
  },
  {
    path: '/order-tracking/view/:id',
    name: 'order-tracking-view',
    component: () => import('@/views/orderTracking/tracking-view/TrackingView.vue'),
    meta: {
      layout: 'content',
      resource: 'KPanel',
    },
  },
  {
    path: '/settings/',
    name: 'settings-view',
    component: () => import('@/views/settings/SettingsView.vue'),
    meta: {
      layout: 'content',
      resource: 'GeneralSettings',
    },
  },

  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
const vueBodyClass = new VueBodyClass(routes)

router.beforeEach((to, _, next) => {
  if (!canNavigate(to)) {
    // Redirect to login if not logged in

    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'dashboard' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
})

export default router
