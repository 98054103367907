import {
  mdiChefHat,
  mdiContactlessPayment,
  mdiCreditCard,
  mdiFileLock,
  mdiFlash,
  mdiHomeOutline,
  mdiMapMarker,
  mdiShieldAccount,
} from '@mdi/js'

/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Sayfalar' },

  { title: 'Ana Sayfa', icon: mdiHomeOutline, to: '/dashboard' },
  { title: 'Sipariş Ver', icon: mdiChefHat, to: '/order/order-to' },
  { title: 'Kolay Sipariş Ver', icon: mdiFlash, to: '/order/order-easy' },
  { title: 'Manuel Ödeme', icon: mdiContactlessPayment, to: '/payment' },
  { title: 'Kart Hareketleri', icon: mdiCreditCard, to: '/credit-card-transactions' },
  { title: 'Kullanıcı Bilgilerim', icon: mdiShieldAccount, to: '/user/view' },
  { title: 'Adreslerim', icon: mdiMapMarker, to: '/user/addresses' },
  { title: 'Sözleşmelerim', icon: mdiFileLock, to: '/user/contracts' },

]
