<template>
  <v-fade-transition mode="out-in">
    <v-btn
      icon

      @click="isDark = !isDark"
    >
      <v-icon
        :key="isDark"
        :size="24"
      >
        {{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
      </v-icon>
    </v-btn>
  </v-fade-transition>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,

      // Icons
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style>
</style>
