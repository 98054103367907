<template>
  <v-app>
    <v-main>
      <slot></slot>
    </v-main>
    <v-snackbar
      v-model="$store.state.app.snackbar.show"
      :timeout="2500"
      text
      top

      outlined
      :color="$store.state.app.snackbar.color"
      elevation="24"
    >
      <div class="text-body-2  text-center">
        {{ $store.state.app.snackbar.text }}
      </div>
    </v-snackbar>
    <cookie-law
      theme="blood-orange--rounded"
      button-text="Kabul Et"
      message="Bu web sitesi, web sitemizde en iyi deneyimi yaşamanızı sağlamak için çerezleri kullanır."
    >
    </cookie-law>
  </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  components: {
    CookieLaw,
  },
}
</script>

<style>
</style>
